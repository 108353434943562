import { RootState } from '~/reducers';
import type { AccountPreferenceState } from '~/reducers/couples/accountPreferencesReducer';

export const getAccountPreferencesLoaded = (state: RootState) =>
  state.couples.accountPreferences.loaded;

export const getHasOptedIntoVendorOutreach = (state: RootState) =>
  state.couples.accountPreferences.FIRST_MOVES_SHARE_FAVORITES.enabled ||
  state.couples.accountPreferences.FIRST_MOVES_SHARE_INQUIRIES.enabled ||
  state.couples.accountPreferences.FIRST_MOVES_SHARE_WEDDING_DETAILS.enabled;

export const getHasOptedIntoVendorOutreachFromFavorites = (state: RootState) =>
  state.couples.accountPreferences.FIRST_MOVES_SHARE_FAVORITES.enabled;

export const getVendorOutreachFromInquiriesPreference = (state: RootState) =>
  state.couples.accountPreferences.FIRST_MOVES_SHARE_INQUIRIES;

export const getVendorOutreachFromFavoritesPreference = (state: RootState) =>
  state.couples.accountPreferences.FIRST_MOVES_SHARE_FAVORITES;

export const getVendorOutreachFromWeddingDetailsPreference = (
  state: RootState
): AccountPreferenceState => state.couples.accountPreferences.FIRST_MOVES_SHARE_WEDDING_DETAILS;

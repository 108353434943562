interface CardLinkWrapperProps {
  className?: string;
  to: string | null;
  openInNewTab?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  title?: string;
}

const CardLinkWrapper = (props: CardLinkWrapperProps): JSX.Element => {
  const { className, to, onClick, children, openInNewTab = true, title } = props;
  if (to) {
    const linkProps = openInNewTab
      ? {
          target: '_blank',
        }
      : {};
    return (
      <a
        className={className}
        href={to}
        {...linkProps}
        onClick={onClick}
        data-testid="card-link-wrapper"
        title={title}
      >
        {children}
      </a>
    );
  }
  return <div className={className}>{children}</div>;
};

export default CardLinkWrapper;

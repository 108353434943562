import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { StorefrontCardView } from '@zola/svc-marketplace-ts-types';
import { FlowEventConfig } from '@zola/tracking-contracts/src/configs';
import { ProductClicked, ProductListViewed } from '@zola/tracking-contracts/src/events';
import { Storefront } from '@zola/tracking-contracts/src/objects';
import {
  trackProductListViewed,
  trackProductClicked,
  trackProductViewed,
} from '@zola/tracking-contracts/src/tracking';

import { CamelCasedPropertiesDeep } from 'type-fest/index.d';

import { VendorCardVendor } from '~/components/common/cards/util/types';
import { MappedAccountStorefront } from '~/types/mappedResponseTypes';
import { VendorCardView } from '~/types/responseTypes';
import { CommonStorefrontDetails } from '~/types/storefrontDetails';
import { getHighestRankedBadge } from '~/util/badgeRanking';
import { getMappedVendor } from '~/util/trackingHelper';

/**
 * We have lots of different ways of representing a storefront.  These are some of
 * them.  Any of these types can be supplied to the helpers for tracking and we'll
 * figure out how to extract the information from the various places it appears
 * in these objects.
 *
 * This type is not exported: you shouldn't be using this as data.
 */
type StorefrontType =
  | VendorCardView
  | CommonStorefrontDetails
  | MappedAccountStorefront
  | StorefrontCardView;

/**
 * Determines if the storefront is a VendorCardView
 */
const isVendorCardView = (storefront: StorefrontType): storefront is VendorCardView => {
  return 'storefrontCoverId' in storefront && 'vendorName' in storefront;
};

/**
 * Determines if the storefront is a StorefrontCardView
 */
const isStorefrontCardView = (storefront: StorefrontType): storefront is StorefrontCardView => {
  return 'storefrontUuid' in storefront && 'name' in storefront;
};

/**
 * Determines if the storefront is a MappedAccountStorefront
 */
const isMappedAccountStorefront = (
  storefront: StorefrontType
): storefront is MappedAccountStorefront => {
  return 'coverImageUrl' in storefront && 'published' in storefront;
};

/**
 * Extracts the taxonomy key from wherever it might live in the storefront
 *
 * @returns SearchableVendorTaxonomyKey
 */
const getTaxonomyKey = (storefront: StorefrontType): SearchableVendorTaxonomyKey => {
  if (isVendorCardView(storefront)) {
    return storefront.taxonomyNode?.key as SearchableVendorTaxonomyKey;
  }
  if (isMappedAccountStorefront(storefront)) {
    return storefront.taxonomyKey;
  }
  if (isStorefrontCardView(storefront)) {
    return storefront.taxonomyNodeKey as SearchableVendorTaxonomyKey;
  }
  return storefront.taxonomyKey;
};

/**
 * Converts the taxonomy key into a tracking category.
 */
const getStorefrontCategory = (storefront: StorefrontType): Storefront['category'] =>
  getBusinessCategory(getTaxonomyKey(storefront));

/**
 * Converts one of many different types of storefront into a payload that can be
 * used with tracking contracts.
 */
export const storefrontToTracking = (
  storefront: StorefrontType
): CamelCasedPropertiesDeep<Storefront> & {
  businessCategory: FlowEventConfig['business_category'];
} => {
  // TODO: We should use the same category as doTrackProductListViewed
  // TODO: We should use the same category as doTrackVendorCardClick
  const category = getStorefrontCategory(storefront);

  if (isVendorCardView(storefront)) {
    return {
      storefrontUuid: storefront.storefrontUuid as string,
      businessCategory: category,
      category,
      name: storefront.vendorName as string,
    };
  }

  if (isMappedAccountStorefront(storefront)) {
    return {
      storefrontUuid: storefront.uuid,
      businessCategory: category,
      category,
      name: storefront.name,
    };
  }

  if (isStorefrontCardView(storefront)) {
    return {
      storefrontUuid: storefront.storefrontUuid,
      businessCategory: category,
      category,
      name: storefront.name,
    };
  }

  return {
    storefrontUuid: storefront.uuid,
    businessCategory: category,
    category,
    name: storefront.name,
  };
};

type TrackingBadge = Required<Storefront>['tags'][number];

/**
 * Is this a badge that should be sent for a Storefront with Tracking contracts.
 */
const isTrackingBadge = (badge: string): badge is TrackingBadge => {
  return Boolean(badge) && ['HIDDEN_GEM', 'NEW', 'TRENDING', 'QUICK_RESPONDER'].includes(badge);
};

/**
 * Should only track award tags on the CLP & SRP where the awards are visible on vendor cards.
 */
const shouldTrackAwardTag = (
  awards?: StorefrontCardView.AwardsEnum[] | null,
  listingShelfName?: ProductListViewed['listing_shelf_name']
) =>
  awards?.length &&
  (listingShelfName === 'CATEGORY_LANDING_PAGE' || listingShelfName === 'SEARCH_RESULTS_PAGE');

const vendorCardVendorToTrackingStorefront = (
  vendorCard: VendorCardVendor,
  listingShelfName?: ProductListViewed['listing_shelf_name'],
  includeTags = false
): Storefront => {
  const { awards, featuredPlacement, storefrontUuid, taxonomyNodeKey, vendorName, badges } =
    vendorCard;

  const displayedBadge = getHighestRankedBadge(badges)?.type;
  const tags: TrackingBadge[] = [];
  if (includeTags && displayedBadge && isTrackingBadge(displayedBadge)) {
    tags.push(displayedBadge);
  }
  if (includeTags && featuredPlacement) {
    tags.push('FEATURED');
  }
  if (includeTags && shouldTrackAwardTag(awards, listingShelfName)) {
    tags.push('BEST_OF_ZOLA');
  }

  return {
    storefront_uuid: storefrontUuid || '',
    category: taxonomyNodeKey
      ? getBusinessCategory(taxonomyNodeKey as SearchableVendorTaxonomyKey)
      : 'UNATTRIBUTED',
    name: vendorName as string,
    tags,
  };
};
/**
 * Using tracking contracts, tracks a product clicked event for a storefront.
 */
export const doTrackVendorCardClickAndView = ({
  vendor,
  listingShelfName,
  includeTags = false,
  ...rest
}: {
  vendor: VendorCardVendor;
  listingShelfName: ProductClicked['listing_shelf_name'];
  includeTags?: boolean;
} & ({ index: number } | { position: number })) => {
  const storefrontForTracking = vendorCardVendorToTrackingStorefront(
    vendor,
    listingShelfName,
    includeTags
  );
  const data: ProductClicked = {
    ...storefrontForTracking,
    business_unit: 'MARKETPLACE',
    business_category: storefrontForTracking.category,
    listing_shelf_name: listingShelfName,
    entity_type: 'STOREFRONT',
    position: 'position' in rest ? rest.position : rest.index,
  };
  trackProductClicked(data);
  trackProductViewed(data);
};

/**
 * Using tracking contracts, tracks a list of storefronts being viewed.
 */
export const doTrackStorefrontListViewed = ({
  storefrontList,
  taxonomyKey,
  listingShelfName,
  includeTags = false,
  ...rest
}: {
  storefrontList: StorefrontCardView[] | VendorCardView[] | VendorCardVendor[];
  taxonomyKey?: SearchableVendorTaxonomyKey;
  listingShelfName?: ProductListViewed['listing_shelf_name'];
  includeTags?: boolean;
} & Partial<ProductListViewed>) => {
  const data: ProductListViewed = {
    products: storefrontList.map((storefront, index) => {
      return {
        ...vendorCardVendorToTrackingStorefront(
          getMappedVendor(storefront) as VendorCardVendor,
          listingShelfName,
          includeTags
        ),
        position: index + 1,
      };
    }),
    business_category: taxonomyKey ? getBusinessCategory(taxonomyKey) : 'UNATTRIBUTED',
    business_unit: 'MARKETPLACE',
    filters: [],
    sorts: [],
    entity_type: 'STOREFRONT',
    listing_shelf_name: listingShelfName,
    ...rest,
  };
  trackProductListViewed(data);
};

import { useCallback, useEffect, useMemo } from 'react';

import { loadAccountPreferences } from '~/actions/couples/accountPreferencesActions';
import { useModal } from '~/contexts/ModalContext';
import { useUserContext } from '~/contexts/UserContext';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getVendorOutreachFromFavoritesPreference } from '~/selectors/couples/accountPreferencesSelectors';
import { isAutomatedTest } from '~/util/automatedTestUtils';

import { FirstMoveOptInModalProps } from './FirstMoveOptInModal';

type AfterFavoriteCallback = () => void;

let requestedPreferences = false;
/**
 * Hook that wraps up modal detection (we don't want to show the opt-in from
 * a modal) and gives back a callback
 */
export const useShowPostFavoriteOptIn = ({
  reopenSourceModal,
  allowInModal = false,
}: {
  reopenSourceModal?: () => void;
  allowInModal?: boolean;
} = {}): AfterFavoriteCallback => {
  const dispatch = useAppDispatch();
  const userContext = useUserContext();
  const { isModalVisible, showV2PredefinedModal } = useModal();

  useEffect(() => {
    if (userContext && !userContext.is_guest && !requestedPreferences) {
      requestedPreferences = true;
      dispatch(loadAccountPreferences()).catch(() => null);
    }
  }, [dispatch, userContext]);

  const vendorOutreachPreference = useAppSelector(getVendorOutreachFromFavoritesPreference);

  const showPrompt = useMemo(() => {
    const hasSelection = vendorOutreachPreference?.madeSelection;
    if (!isAutomatedTest()) {
      if (hasSelection) {
        if (vendorOutreachPreference?.enabled || !vendorOutreachPreference.rePromptEligible) {
          return false;
        }
        // No selection made or re-prompt eligible
        return true;
      }
    }
    // Have they ever answered the opt-in question, not have they answered TRUE
    return !hasSelection;
  }, [
    vendorOutreachPreference?.enabled,
    vendorOutreachPreference?.madeSelection,
    vendorOutreachPreference?.rePromptEligible,
  ]);

  const callback: AfterFavoriteCallback = useCallback(() => {
    if (userContext && !userContext.is_guest && (allowInModal || !isModalVisible) && showPrompt) {
      showV2PredefinedModal<FirstMoveOptInModalProps>(
        'FIRST_MOVE_OPT_IN_MODAL',
        {
          closeOnEscape: false,
          closeOnOverlayClick: false,
          showCloseButton: false,
          dialogHeadingId: 'first-move-opt-in',
          triggerRef: null,
          lockBgScrolling: true,
        },
        {
          onComplete: reopenSourceModal,
        }
      );
    }
  }, [
    allowInModal,
    isModalVisible,
    reopenSourceModal,
    showPrompt,
    showV2PredefinedModal,
    userContext,
  ]);

  return callback;
};

import { Fragment, useMemo } from 'react';

import { VendorCardBadgeView } from '@zola/svc-marketplace-ts-types';
import { TagV2, TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';

import cx from 'classnames';

import DiamondIcon from '~/assets/images/search-tile-badges/diamond.svg';
import TrendingUpIcon from '~/assets/images/search-tile-badges/trending-up.svg';

import { getHighestRankedBadge } from '../../../util/badgeRanking';

import styles from './ResponsiveVendorTagV2.module.less';

type ResponsiveVendorTagV2Props = {
  badges: VendorCardBadgeView[] | null;
  size?: TagV2Size;
};

const badgeColor: Record<string, TagV2Variant> = {
  QUICK_RESPONDER: TagV2Variant.WHITE,
  TRENDING: TagV2Variant.COVE,
  HIDDEN_GEM: TagV2Variant.PURPLE,
  NEW: TagV2Variant.GREEN,
};

export const ResponsiveVendorTagV2Content = ({
  className,
  badges,
  size,
}: ResponsiveVendorTagV2Props & { className?: string }): JSX.Element | null => {
  const topBadge = useMemo(() => getHighestRankedBadge(badges), [badges]);
  const topBadgeDisplayText = topBadge?.message;
  const topBadgeType = topBadge?.type;
  const variant = topBadgeType ? badgeColor[topBadgeType] : TagV2Variant.WHITE;
  if (topBadgeDisplayText) {
    return (
      <TagV2 size={size} className={className} variant={variant}>
        {topBadgeType === 'TRENDING' && (
          <img className={styles.icon} src={TrendingUpIcon} alt="Trendline" />
        )}
        {topBadgeType === 'HIDDEN_GEM' && (
          <img className={styles.icon} src={DiamondIcon} alt="Gem" />
        )}
        <span className={cx(styles.tagText)}>{topBadgeDisplayText}</span>
      </TagV2>
    );
  }
  return null;
};

export const ResponsiveVendorTagV2 = (props: ResponsiveVendorTagV2Props): JSX.Element => {
  const badgeOrNull = ResponsiveVendorTagV2Content({ ...props, className: styles.tagContainer });
  if (badgeOrNull) return badgeOrNull;
  return <Fragment />;
};

export default ResponsiveVendorTagV2;

import { Fragment } from 'react';

import { VendorCardBadgeView } from '@zola/svc-marketplace-ts-types';
import { CheckIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Check';
import { TagV2, TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';

import { sentenceCase } from '~/util/textUtils';

import styles from './badgeList.module.less';

const BadgeList = ({
  badges,
  variant = TagV2Variant.GRAY,
}: {
  badges: VendorCardBadgeView[];
  variant?: TagV2Variant;
}) => {
  const displayableBadges = [
    'MATCHING_WEDDING_DATE',
    'MATCHING_LOCATION',
    'MATCHING_BUDGET',
    'MATCHING_FLORIST_ETHICS',
    'MATCHING_PHOTOG_PERSONALITY',
    'MATCHING_PHOTOG_STYLE',
    'MATCHING_VENUE_ATMOSPHERE',
    'MATCHING_VENUE_CAPACITY',
  ];
  return (
    <div className={styles.badgeList}>
      {badges.map((badge, index) => {
        if (badge.type && displayableBadges.includes(badge.type)) {
          return (
            <TagV2 size={TagV2Size.SMALL} variant={variant} key={`badge-${index}`}>
              <CheckIcon height={14} width={14} />
              &nbsp;{sentenceCase(badge.message)}
            </TagV2>
          );
        }
        return <Fragment key={`badge-${index}`} />;
      })}
    </div>
  );
};

export default BadgeList;

import { useMemo } from 'react';

import { StorefrontCardView } from '@zola/svc-marketplace-ts-types';
import { CalendarEventIcon } from '@zola/zola-ui/src/components/SvgIconsV3/CalendarEvent';
import { TagIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Tag';
import COLORS3, { COLORS_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colors3';

import { TagWrapper } from './ListingMessageTag.styles';

interface ListingMessageTagProps {
  type: StorefrontCardView.ListingMessageTypeEnum;
}

export const ListingMessageTag = ({ type }: ListingMessageTagProps): JSX.Element | null => {
  const tagData = useMemo(() => {
    if (type === 'OFFER') {
      return {
        text: 'Offer',
        icon: TagIcon,
        color: COLORS_SEMANTIC.POSITIVE_100,
      };
    }

    if (type === 'OPEN_HOUSE') {
      return {
        text: 'Open house',
        icon: CalendarEventIcon,
        color: COLORS3.BAY_100,
      };
    }

    return null;
  }, [type]);

  if (!tagData) return null;

  const { text, icon: IconComponent, color } = tagData;
  return (
    <TagWrapper color={color}>
      <IconComponent width={16} height={16} />
      <span>{text}</span>
    </TagWrapper>
  );
};

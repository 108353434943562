import { Settings } from 'react-slick';

export const CAROUSEL_SETTINGS: Settings = {
  slidesPerRow: 1,
  dots: true,
  rows: 1,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        swipeToSlide: true,
        swipe: true,
      },
    },
  ],
  lazyLoad: 'progressive',
};

import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

import styled from '@emotion/styled';

export const TagWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.color || COLORS3.BAY_100};
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  ${FONT.textSizeCompact(FONT.SIZE.EXTRA_SMALL)}

  ${MEDIA_QUERY_V2.MOBILE} {
    font-size: ${FONT.SIZE.SMALLER};
  }
`;

import React, { Component, createRef, RefObject } from 'react';

import { ArrowButton } from '@zola/zola-ui/src/components/ArrowButton';
import { ChevronButton } from '@zola/zola-ui/src/components/ChevronButton';

import cx from 'classnames';
import _forEach from 'lodash/forEach';
import Slider, { Settings } from 'react-slick';

import './carousel.less';

// Hack to fix error of passing props to html tags.
const SlickButtonFix = ({
  currentSlide: _currentSlide,
  slideCount: _slideCount,
  ...props
}: {
  smaller: boolean | undefined;
  direction: string;
  component: string;
  currentSlide?: number;
  slideCount?: number;
}) => <ArrowButton {...props} />;

export const defaultSettings: Partial<Settings> = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  lazyLoad: 'ondemand', // 'ondemand' is default, other option is 'progressive'
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const defaultProps = {
  btnStyle: 'primary',
  settings: defaultSettings,
  ariaLabelSlideType: 'Slide',
  padSlides: false,
  useV2ArrowStyles: false,
  btnInverse: false,
};

interface CarouselProps {
  content: React.ReactNode[];
  /** settings to configure the underlying carousel.  See https://react-slick.neostack.com/docs/api/  */
  settings?: Partial<Settings> & {
    beforeChangeHook?: (oldIndex: number, newIndex: number) => void;
    afterChangeHook?: (index: number) => void;
  };
  btnStyle?: 'primary' | 'secondary' | 'tertiary';
  className?: string;
  ariaLabelSlideType?: string;
  /** If set to true, the slides will be padded to an even multiple of the slidesToShow setting to prevent disappearing slides. */
  padSlides?: boolean;
  useV2ArrowStyles?: boolean;
  useSmallerV2Arrows?: boolean;
  btnInverse?: boolean;
  noArrows?: boolean;
}

type CarouselState = {
  isTransitioning: boolean;
  extraSlides: JSX.Element[];
  lastScreenWidth: number | null;
};

class Carousel extends Component<CarouselProps & typeof defaultProps, CarouselState> {
  private node: HTMLDivElement | null | undefined;

  private readonly sliderRef: RefObject<Slider>;

  public static defaultProps = defaultProps;

  constructor(props: CarouselProps & typeof defaultProps) {
    super(props);

    this.state = {
      isTransitioning: false,
      extraSlides: [],
      lastScreenWidth: null,
    };

    this.afterChange = this.afterChange.bind(this);
    this.beforeChange = this.beforeChange.bind(this);
    this.getVisibleSlideCount = this.getVisibleSlideCount.bind(this);
    this.onInit = this.onInit.bind(this);
    this.onLazyLoad = this.onLazyLoad.bind(this);
    this.onReInit = this.onReInit.bind(this);
    this.updateActiveDots = this.updateActiveDots.bind(this);
    this.updateVisibility = this.updateVisibility.bind(this);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.getExtraSlides = this.getExtraSlides.bind(this);

    this.sliderRef = createRef();
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onInit() {
    this.updateActiveDots();
    this.updateVisibility();
  }

  onLazyLoad() {
    const { isTransitioning } = this.state;
    if (isTransitioning) return;
    this.onInit();
  }

  onReInit() {
    const { isTransitioning } = this.state;
    if (isTransitioning) return;
    this.onInit();
  }

  getVisibleSlideCount() {
    if (!this.node) return 0;
    const slides = this.node.querySelectorAll('.slick-slide.slick-active') || [];
    return slides.length;
  }

  /**
   * When the screen resizes, or initially draws, this will, if configured,
   * create fake slides and add them to the carousel.   This works around
   * a bug with our attempts to set visibility for items in the carousel.
   * For accessibility reasons, we need visible=false, aria-hidden=true on
   * any slide that is not visible.  The underlying react-slick component does
   * some interesting things to show slides (cloning and using css transforms)
   * that makes it difficult to get the visibility settings correct.  However,
   * if we have the an even multiple of the slides in the carousel it works.
   *
   */
  getExtraSlides(width?: number) {
    const { settings, padSlides } = this.props;
    if (!padSlides) return;

    const { content } = this.props;

    let numActuallyShown = settings.slidesToShow as number;
    if (settings.responsive) {
      const sortedResponsiveSettings = settings.responsive.sort(
        (a, b) => a.breakpoint - b.breakpoint
      );
      const appliedSetting = sortedResponsiveSettings.find(
        (setting) => setting.breakpoint >= (width as number)
      );

      if (appliedSetting && appliedSetting.settings) {
        numActuallyShown = (appliedSetting.settings as Settings).slidesToShow as number;
      }
    }

    const fakeSlides = [];
    if (content.length % numActuallyShown !== 0) {
      const fakeSlideCount = numActuallyShown - (content.length % numActuallyShown);
      for (let i = 0; i < fakeSlideCount; i += 1) {
        fakeSlides.push(
          <div className="fake-slide" key={`fake-slide-${i}`} style={{ visibility: 'hidden' }} />
        );
      }
    }
    this.setState({ extraSlides: fakeSlides });
  }

  updateDimensions() {
    // On mobile, resize events will fire as you scroll the screen
    // so we need anything dealing with resize to only fire if the
    // width has actually changed.  Its subtle, but on actual devices
    // (or simulators) the browser nav bar and nav buttons change sizes
    // during scroll, changing the height, but not the width.
    const currentScreenWidth = window.innerWidth;
    const { lastScreenWidth } = this.state;
    const hasWidthChanged = currentScreenWidth !== lastScreenWidth;

    if (hasWidthChanged) {
      // When the screen resizes, we _could_ end up in a situation where we
      // showing a page that no longer exists, or we're offset into the slide
      // index incorrectly because of the fake cards we've added, so we'll
      // jump back to the first card.  This isn't ideal.
      if (this.sliderRef.current) {
        this.sliderRef.current.slickGoTo(0);
      }
      this.getExtraSlides();
    }

    this.setState({ lastScreenWidth: currentScreenWidth });
  }

  afterChange(index: number) {
    const { settings } = this.props;

    this.setState({ isTransitioning: false });
    this.updateActiveDots();
    this.updateVisibility();

    if (settings.afterChangeHook) {
      settings.afterChangeHook(index);
    }
  }

  beforeChange(oldIndex: number, newIndex: number) {
    const { settings } = this.props;

    this.setState({ isTransitioning: true });
    if (!this.node) return;
    const slides = this.node.querySelectorAll('.slick-slide') || [];
    _forEach(slides, (s) => {
      // eslint-disable-next-line no-param-reassign
      (s as HTMLElement).style.visibility = 'visible';
    });

    if (settings.beforeChangeHook) {
      settings.beforeChangeHook(oldIndex, newIndex);
    }
  }

  customPaging(index: number) {
    const slideNumber = index + 1;

    return (
      <button
        type="button"
        className="carousel-dot__container"
        aria-label={`Select Slide ${slideNumber}`}
      >
        <div className="carousel-dot" />
      </button>
    );
  }

  updateVisibility() {
    const { padSlides } = this.props;
    if (!this.node) return;
    const slides = this.node.querySelectorAll<HTMLElement>('.slick-slide') || [];
    _forEach(slides, (s) => {
      let visibility = 'hidden';
      if (s.className.indexOf('slick-active') > -1) {
        visibility = 'visible';
        if (padSlides) {
          // If we created fake slides, we should hide them from screen readers.
          if ((s.querySelectorAll('.fake-slide') || []).length > 0) {
            visibility = 'hidden';
            s.setAttribute('aria-hidden', 'true');
          }
        } else if (s.getAttribute('aria-hidden') === 'false') {
          // Remove aria-hidden="false" for web accessibility
          s.removeAttribute('aria-hidden');
        }
      }

      // eslint-disable-next-line no-param-reassign
      s.style.visibility = visibility;
    });
  }

  updateActiveDots() {
    if (!this.node) return;
    const dots = this.node.querySelectorAll<HTMLElement>('.carousel-dot') || [];
    _forEach(dots, (dot) => {
      const classNames =
        ((dot.parentNode as HTMLElement).parentNode as HTMLElement).className || '';
      const isSelected = classNames.indexOf('slick-active') > -1;
      const ariaCurrent = isSelected ? 'true' : 'false';
      (dot.parentNode as HTMLElement).setAttribute('aria-current', ariaCurrent);
    });
  }

  render() {
    const {
      content,
      settings,
      btnStyle,
      className,
      ariaLabelSlideType,
      useV2ArrowStyles,
      btnInverse,
      useSmallerV2Arrows,
    } = this.props;
    const classes = cx('carousel__container', className);
    const visibleSlideCount = this.getVisibleSlideCount();
    const multipleVisibleSlides = visibleSlideCount > 1;
    const countText = multipleVisibleSlides ? ` ${visibleSlideCount}` : '';
    const typeText = multipleVisibleSlides ? `${ariaLabelSlideType}s` : ariaLabelSlideType;
    const ariaLabelNext = `Next${countText} ${typeText}`;
    const ariaLabelPrevious = `Previous${countText} ${typeText}`;
    const { extraSlides } = this.state;

    return (
      <div
        className={classes}
        ref={(node) => {
          this.node = node;
        }}
      >
        <Slider
          nextArrow={
            useV2ArrowStyles ? (
              <SlickButtonFix
                smaller={useSmallerV2Arrows}
                direction="right"
                component="button"
                aria-label={ariaLabelNext}
              />
            ) : (
              <ChevronButton
                direction="right"
                btnStyle={btnStyle}
                inverse={btnInverse}
                ariaLabel={ariaLabelNext}
              />
            )
          }
          prevArrow={
            useV2ArrowStyles ? (
              <SlickButtonFix
                smaller={useSmallerV2Arrows}
                direction="left"
                component="button"
                aria-label={ariaLabelPrevious}
              />
            ) : (
              <ChevronButton
                direction="left"
                btnStyle={btnStyle}
                inverse={btnInverse}
                ariaLabel={ariaLabelPrevious}
              />
            )
          }
          afterChange={this.afterChange}
          beforeChange={this.beforeChange}
          customPaging={this.customPaging}
          onInit={this.onInit}
          onLazyLoad={this.onLazyLoad}
          onReInit={this.onReInit}
          ref={this.sliderRef}
          {...settings}
        >
          {content}
          {extraSlides}
        </Slider>
      </div>
    );
  }
}

export default Carousel;

import { Fragment } from 'react';

import { StorefrontSocialProofView } from '@zola/svc-marketplace-ts-types';

import styles from './socialProof.module.less';

const ProofPoint: React.FC = ({ children }) => <p className={styles.proofPoint}>{children}</p>;

/**
 * Displays unadorned social proof from StorefrontCardView.
 */
export const SocialProof = ({
  socialProof,
}: {
  socialProof: StorefrontSocialProofView;
}): JSX.Element => {
  const { couplesBooked, couplesConsidering } = socialProof;

  if (couplesBooked > 1) {
    return <ProofPoint>{couplesBooked} Zola couples have booked</ProofPoint>;
  }
  if (couplesConsidering > 1) {
    return <ProofPoint>{couplesConsidering} Zola couples currently interested</ProofPoint>;
  }

  return <Fragment />;
};

import { useState, useCallback } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { isLargeDesktop, isXsMobile } from '@zola-helpers/client/dist/es/util/responsive';
import { VendorCardBadgeView, VenueDetailView } from '@zola/svc-marketplace-ts-types';
import { ProductClicked } from '@zola/tracking-contracts/src/events';
import StarRatings from '@zola/zola-ui/src/components/StarRatings/StarRatings';
import { TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';

import cx from 'classnames';

import BestOfZolaPng from '~/assets/images/badges/best_of_zola.png';
import CardLinkWrapper from '~/components/common/cards/components/CardLinkWrapper';
import {
  getDisplayPrice,
  getVendorsPerPrice,
  showPerEntityPricing,
} from '~/components/common/cards/util/helper';
import { doTrackVendorCardClickAndView } from '~/contexts/TrackingContracts/trackingContractsHelpers';
import { filterSupportedAwards } from '~/meta/awards';
import { formatLocation } from '~/util/formatters';
import { formatRating } from '~/util/reviewUtils';
import { getCanonicalUrl } from '~/util/storefrontUrl';
import { TrackingProductLocation } from '~/util/trackingHelper';
import { formatVenueDetails } from '~/util/vendorUtils';

import { renderDefaultFavoriteButton } from '../../favorites/buttonShapes/renderDefaultFavoriteButton';
import { StorefrontFavoriteButton } from '../../favorites/StorefrontFavoriteButton';
import { useShowPostFavoriteOptIn } from '../../modals/InvitesFromVendorsOptIn/showPostFavoriteOptIn';
import { ListingMessageTag } from '../components/ListingMessageTag';
import { VendorCardVendor } from '../util/types';
import { AvailabilityTag } from './components/AvailabilityTag';
import BadgeList from './components/BadgeList';
import {
  CardDetails,
  getPricingCardDetailMeta,
  getVendorCardPriceMetaFields,
} from './components/CardDetails';
import VendorTypeAndLocation from './components/VendorTypeAndLocation';
import { VendorCardPhoto, wasCardPhotoClick } from './VendorCardPhoto';

import styles from './vendorCard.module.less';

export type VendorCardProps = {
  /**
   * Result of vendorCardViewToVendorCardVendor or storefrontCardViewToVendorCardVendor mapper
   *
   * Please do not change this prop to accept any other types; instead create a new mapper
   * that outputs this type, or update the existing mappers
   */
  vendor: VendorCardVendor;
  availabilityDates?: string[] | null | string;
  /** 1 based position of the card for tracking */
  position: number;
  onClick?: ((event: React.MouseEvent<HTMLElement>) => void) | null;
  scrollContainer?: string | Element;

  /**
   * Show the Best of Zola award for award recipients
   *
   * Default: false
   */
  showAward?: boolean;

  /** single badge in upper left overlaying image */
  showOverlayBadge?: boolean;
  /** list of badges below card details */
  showBadgeList?: boolean;
  /**
   * Optional tag color variant to use for the badges.  If we need different color
   * tags, we can add a content slot for the tags (badgeList?: React.ReactNode).
   */
  badgeColorVariant?: TagV2Variant;

  /**
   * Show where the vendor is based
   *
   * Default: true
   */
  showLocation?: boolean;

  /**
   * Show if the vendor services the "market".  When set, this takes the
   * metroType from the VendorCardVendor and the queriedLocation and combines
   * them to show "Based in <queried location>", "Serves <queried location>", or
   * "Serves <queried location> for a fee" (text may vary).
   *
   * Default: true
   */
  showServiceLocation?: boolean;
  /**
   * Instead of showing _nothing_ if the vendor is based in the queriedLocation,
   * show "Based in <queried location>" where we would show the other service
   * info.
   *
   * Defaults to false
   */
  showHomeService?: boolean;

  /**
   * A string, probably City, StateCode, indicating where the couple is currently
   * searching, or possibly their wedding location (LP, CLP).  When showServiceLocation
   * is set, this location is displayed when the vendor is based in that location,
   * services that location for free, or services that location for a fee.  That
   * "service" determination comes from the VendorCardVendor's metroType field.
   */
  queriedLocation?: string;

  /**
   * Show the favorite/unfavorite button.
   *
   * Default: true
   */
  showFavorites?: boolean;
  /**
   * Show the storefronts starting price, if we have it, and the storefront has
   * has opted to show price.
   *
   * Default: true
   */
  showPrice?: boolean;
  /**
   * Show review rating summaries if the storefront has reviews.
   *
   * Default: true
   */
  showRatings?: boolean;

  /**
   * For some vendor categories, show additional details if present in the vendor
   * card vendor.  For example: show facet information (team size, indoor/outdoor,
   * service level, etc).
   *
   * Default: true
   */
  showDetails?: boolean;

  /**
   * Show the vendor category.  Most useful when mixed cards are shown in the same
   * section.
   *
   * Default: true
   */
  showVendorType?: boolean;

  readOnly?: boolean;
  searchLocation?: string | null;

  /** Show the carousel: default: true. */
  showCarousel?: boolean;
  carouselPhotoIds?: string[];
  /** Where is this card being displayed?  Used for tracking favorite clicks */
  location?: TrackingProductLocation;

  /** Tracking Prop  */
  listingShelfName?: ProductClicked['listing_shelf_name'];
  trackClick: boolean;

  lazyload?: boolean;
  className?: string;

  /** Scale the card size when hovered, default true. */
  scaleOnHover?: boolean;
  /** self-contained image scale, where containing div does not expand, img only "zooms in" */
  altScaleOnHover?: boolean;

  /**
   * Vendor cards have a 20px bottom margin on them.  That should not be part
   * of the component: margin should be specified where the card is used.  However
   * to just globally sweep away the margin is maybe too much to bite off at
   * once, so this property is here to migrate to no-margin cards.  When noMargin
   * is false everywhere, we can remove the prop.  Deal?
   */
  noMargin?: boolean;
};

/** Generate a text title for the vendor card link */
export const getVendorCardTitle = (vendor: VendorCardVendor): string | undefined => {
  const locationString =
    vendor.city && vendor.stateProvince ? `${vendor.city}, ${vendor.stateProvince}` : undefined;

  if (vendor.taxonomyNodeKey === VENUES_TAXONOMY_KEY && vendor.details) {
    const { serviceLevel, venueSetting, capacity } = vendor.details as VenueDetailView;

    const { formattedServiceLevels, formattedVenueSettings, displayCapacityText } =
      formatVenueDetails(serviceLevel || [], venueSetting || [], capacity?.max);

    const parts = [vendor.vendorName];

    if (formattedServiceLevels || formattedVenueSettings) {
      if (Boolean(formattedServiceLevels)) {
        parts.push(`${formattedServiceLevels.toLowerCase()}`);
      }
      if (Boolean(formattedVenueSettings)) {
        parts.push(`${formattedVenueSettings.toLowerCase()}`);
      }
    }
    parts.push('wedding venue');

    if (locationString) {
      parts.push(`in ${locationString}`);
    }
    if (displayCapacityText) {
      parts.push('with capacity for ' + displayCapacityText.toLowerCase());
    }

    const article = ['a', 'e', 'i', 'o', 'u'].includes(parts?.[1]?.[0] as string) ? 'an' : 'a';

    const firstSentence = [parts[0], article, ...parts.slice(1)].join(' ');

    const priceMeta = getPricingCardDetailMeta(getVendorCardPriceMetaFields({ vendor }));
    if (priceMeta?.label) {
      return [firstSentence, `Pricing ${priceMeta.label.toLowerCase()}`].join('. ').trim();
    }

    return firstSentence;
  }
  return undefined;
};

export const VendorCard = (props: VendorCardProps): JSX.Element => {
  const {
    vendor,
    searchLocation = null,
    onClick,
    availabilityDates,
    position,
    location,
    scrollContainer,
    showAward = false,
    showOverlayBadge = true,
    showBadgeList = false,
    showLocation = true,
    showServiceLocation = true,
    showHomeService = false,
    queriedLocation,
    showFavorites = true,
    showDetails = true,
    showVendorType = true,
    showRatings = true,
    showPrice = true,
    readOnly = false,
    showCarousel = true,
    carouselPhotoIds,
    lazyload = true,
    badgeColorVariant,
    className,
    scaleOnHover = true,
    altScaleOnHover,
    noMargin = false,
    listingShelfName,
    trackClick,
  } = props;
  const {
    awards,
    storefrontUuid,
    priceRangeVisible,
    priceTier,
    vendorName,
    taxonomyNodeKey,
    storefrontSlug,
    details,
    metroType,
    reviewsCount,
    badges,
    price,
    searchScore,
    displayAvailability,
    availableDates: vendorAvailableDates,
  } = vendor;
  const to =
    storefrontSlug && taxonomyNodeKey && !readOnly
      ? getCanonicalUrl(taxonomyNodeKey, storefrontSlug)
      : null;
  const displayAward = showAward && filterSupportedAwards(awards).length > 0;
  const locationString =
    vendor.city && vendor.stateProvince
      ? `${vendor.city}, ${vendor.stateProvince}`
      : searchLocation;
  const displayLocation = showLocation ? locationString : null;
  const hasNoMinumumPrice = price?.min === null || price?.min === undefined;
  const whichDisplayPrice = showPerEntityPricing(taxonomyNodeKey, price)
    ? getVendorsPerPrice(taxonomyNodeKey, details)
    : getDisplayPrice(price?.min);
  const displayPrice = showPrice && priceRangeVisible ? whichDisplayPrice : null;

  const showRatingStars =
    showRatings && Boolean(vendor.averageReviewsRate && reviewsCount && reviewsCount > 0);

  const doTrackClick = useCallback(
    (includeTags: boolean) => {
      doTrackVendorCardClickAndView({
        vendor,
        position,
        listingShelfName,
        includeTags,
      });
    },
    [position, vendor, listingShelfName]
  );

  const showBadgeTag = Boolean(badges && showOverlayBadge && !showBadgeList);

  const filterCarouselClicks = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (wasCardPhotoClick(event)) {
        const target = event.target as HTMLElement;
        const heartClicked = target.className.indexOf('favorite-icon') >= 0;
        if (!heartClicked) {
          if (onClick) onClick(event);
          if (trackClick) doTrackClick(showBadgeTag);
        }
      }
    },
    [doTrackClick, onClick, showBadgeTag, trackClick]
  );

  const showPostFavoriteOptIn = useShowPostFavoriteOptIn();

  const { isDesktop: initialDesktop } = useResponsiveDesign();
  const [tagSize, setTagSize] = useState<TagV2Size>(
    initialDesktop ? TagV2Size.LARGE : TagV2Size.SMALL
  );
  useEffectOnce(() => {
    if (isLargeDesktop() || isXsMobile()) {
      setTagSize(TagV2Size.LARGE);
    } else {
      setTagSize(TagV2Size.SMALL);
    }
  });

  return (
    <div
      className={cx(styles.cardContainer, className, { [styles.noMargin]: noMargin })}
      data-score={searchScore}
    >
      <CardLinkWrapper
        className={cx(styles.vendorCard, {
          [styles.readOnly]: readOnly,
        })}
        to={to}
        onClick={filterCarouselClicks}
        title={vendorName ?? undefined}
      >
        <VendorCardPhoto
          scaleOnHover={scaleOnHover}
          altScaleOnHover={altScaleOnHover}
          vendor={vendor}
          showBadges={showBadgeTag}
          tagSize={tagSize}
          showCarousel={showCarousel}
          carouselPhotoIds={carouselPhotoIds || []}
          lazyload={lazyload}
          readOnly={readOnly}
          scrollContainer={scrollContainer}
        >
          {storefrontUuid && showFavorites && !readOnly && (
            <StorefrontFavoriteButton
              afterFavoriteCallback={showPostFavoriteOptIn}
              location={location}
              suppressToast
              tilePosition={position}
              uuid={storefrontUuid}
            >
              {renderDefaultFavoriteButton({
                className: styles.favoriteButton,
                variant: 'v3',
                type: 'VENDOR',
              })}
            </StorefrontFavoriteButton>
          )}
        </VendorCardPhoto>
        <div className={cx(styles.body, { [styles.bodyWithAward]: displayAward })}>
          <div>
            {showVendorType && (
              // Note, this does not show location!
              <VendorTypeAndLocation
                vendorTypeKey={taxonomyNodeKey}
                details={details}
                showVendorType={showVendorType}
              />
            )}
            <div className={styles.vendorName} data-testid="vendor-name">
              {vendorName}
            </div>
          </div>
          <div className={cx({ [styles.vendorCardDetailsContainer]: displayAward })}>
            <div className={styles.vendorCardDetails}>
              <div className={styles.starRatingsLocation}>
                {showRatingStars && vendor.averageReviewsRate && reviewsCount && (
                  <StarRatings
                    activeStars={formatRating(vendor.averageReviewsRate)}
                    fillColor={COLORS3.YELLOW_100}
                    reviewCount={reviewsCount.toString()}
                    textSize="small"
                    totalStars={5}
                  />
                )}
                {displayLocation && (
                  <span className={styles.location} data-test-id="location">
                    {formatLocation(displayLocation)}
                  </span>
                )}
              </div>
              <CardDetails
                displayPrice={displayPrice}
                displayLocation={showServiceLocation}
                queriedLocation={queriedLocation}
                details={details}
                metroType={metroType}
                hasNoMinumumPrice={hasNoMinumumPrice}
                priceTier={priceTier}
                vendorTypeKey={taxonomyNodeKey}
                socialProof={vendor.socialProof}
                showDetails={showDetails}
                showHomeService={showHomeService}
              />
              {vendor.listingMessageType && <ListingMessageTag type={vendor.listingMessageType} />}
              {!!availabilityDates && !showBadgeList && (
                <AvailabilityTag
                  displayAvailability={displayAvailability}
                  vendorAvailableDates={vendorAvailableDates}
                />
              )}
              {showBadgeList && ((badges && badges?.length) || 0) > 0 && (
                <BadgeList badges={badges as VendorCardBadgeView[]} variant={badgeColorVariant} />
              )}
            </div>
            {displayAward ? (
              <div className={styles.bestOfZolaAward}>
                <img alt="Awarded Best of Zola" height={56} src={BestOfZolaPng} width={56} />
              </div>
            ) : null}
          </div>
        </div>
      </CardLinkWrapper>
    </div>
  );
};

export default VendorCard;

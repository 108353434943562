import React, { useMemo } from 'react';

import { TagV2Size } from '@zola/zola-ui/src/components/Tag/TagV2.styles';

import cx from 'classnames';

import Carousel from '~/components/common/zolaUI/Carousel/Carousel';
import { CAROUSEL_SETTINGS } from '~/util/carouselSettings';
import { getVendorIconV3 } from '~/util/vendorUtils';

import { ResponsiveVendorTagV2 } from '../../badges/ResponsiveVendorTagV2';
import CachedImage from '../../images/CachedImage';
import { VendorCardVendor } from '../util/types';

import styles from './vendorCardPhoto.module.less';

// Turning off lazy loading allows the carousel to render on the server, and the images are
// already lazy-loaded via the `loading` attribute
const carouselSettings = {
  ...CAROUSEL_SETTINGS,
  lazyLoad: undefined,
};

type AspectRatio = '3:2' | '4:3';

const AspectRatioMath: Record<AspectRatio, number> = {
  '3:2': 0.67,
  '4:3': 0.75,
};

const AspectRatioStyles: Record<AspectRatio, string | undefined> = {
  '3:2': undefined, // this is the default in the card
  '4:3': styles.aspectRatio4_3,
};

const srcSizes = (ratio: AspectRatio = '3:2') => {
  return [200, 250, 300, 400, 500, 600].map((width) => ({
    width,
    height: Math.round(width * AspectRatioMath[ratio]),
  }));
};

export const wasCardPhotoClick = (event: React.MouseEvent<HTMLElement>) => {
  const target = event.target as HTMLElement;
  if (!target) return false;

  const arrowClicked = target.className.indexOf('slick-arrow') >= 0;
  const dotClicked = target.className.indexOf('carousel-dot') >= 0;
  if (!arrowClicked && !dotClicked) {
    return true;
  }
  return false;
};

type CardPhotoProps = {
  imageIds: string[];
  alternateImage: React.ReactNode;
  tagContent?: React.ReactNode;

  /** Scale the card size when hovered, default true. */
  scaleOnHover: boolean;

  /** Self-contained image scale, where containing div does not expand, img only "zooms in" */
  altScaleOnHover?: boolean | undefined;
  showCarousel: boolean;
  lazyload: boolean;
  readOnly: boolean;
  scrollContainer: string | Element | undefined;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  aspectRatio?: AspectRatio;
  photoOnClick?: (imageId: string) => void;
};

type VendorCardPhotoProps = {
  vendor: VendorCardVendor;

  showBadges: boolean;
  carouselPhotoIds: string[];

  tagSize: TagV2Size;
} & Omit<CardPhotoProps, 'imageIds' | 'alternateImage' | 'tagContent'>;

export const CardPhoto: React.FC<CardPhotoProps> = ({
  alternateImage,
  altScaleOnHover,
  children,
  className,
  imageIds,
  lazyload,
  onClick,
  photoOnClick,
  readOnly,
  scaleOnHover,
  showCarousel,
  tagContent,
  aspectRatio = '3:2',
}) => {
  const carouselCards = useMemo(() => {
    return imageIds.map((imageId, index) => (
      <div
        className={cx(styles.carouselPhoto, AspectRatioStyles[aspectRatio])}
        id={`photo-${index}`}
        key={imageId}
      >
        <CachedImage
          uuid={imageId}
          srcSizes={srcSizes(aspectRatio)}
          fit="crop"
          quality={60}
          loading={lazyload ? 'lazy' : 'eager'}
          onClick={(event) => {
            if (photoOnClick && wasCardPhotoClick(event)) {
              photoOnClick(imageId);
            }
          }}
        />
      </div>
    ));
  }, [imageIds, aspectRatio, lazyload, photoOnClick]);

  const showCoverCarousel = showCarousel && !readOnly && imageIds.length > 1;
  const showCoverPhoto = !showCoverCarousel && imageIds.length > 0;

  return (
    <div
      className={cx(
        styles.cardBody,
        AspectRatioStyles[aspectRatio],
        {
          [styles.scaleOnHover]: !readOnly && scaleOnHover,
          [styles.altScaleOnHover]: !readOnly && altScaleOnHover,
        },
        className
      )}
      onClick={onClick}
    >
      {tagContent}
      {showCoverCarousel ? (
        <Carousel
          className={styles.carouselContainer}
          content={carouselCards}
          settings={carouselSettings}
          useV2ArrowStyles
          useSmallerV2Arrows
        />
      ) : (
        <div className={cx(styles.carouselPhoto, AspectRatioStyles[aspectRatio])}>
          {showCoverPhoto ? (
            <CachedImage
              uuid={imageIds[0]}
              srcSizes={srcSizes(aspectRatio)}
              fit="crop"
              quality={60}
              loading={lazyload ? 'lazy' : 'eager'}
              onClick={(event) => {
                if (photoOnClick) {
                  event.stopPropagation();
                  event.preventDefault();

                  photoOnClick(imageIds[0]);
                }
              }}
            />
          ) : (
            alternateImage
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export const VendorCardPhoto: React.FC<VendorCardPhotoProps> = (props) => {
  const { tagSize, vendor, showBadges, carouselPhotoIds, ...rest } = props;

  const { taxonomyNodeKey, imageIds: vendorPhotoIds, badges, storefrontCoverId } = vendor;

  const imageIds = useMemo(() => {
    if (vendorPhotoIds?.length) {
      return vendorPhotoIds.slice(0, 5);
    }

    if (carouselPhotoIds?.length) {
      const uniqueIds = new Set([storefrontCoverId, ...carouselPhotoIds]);
      return [...uniqueIds].filter(Boolean).slice(0, 5) as string[];
    }

    if (storefrontCoverId) {
      return [storefrontCoverId];
    }

    return [];
  }, [carouselPhotoIds, vendorPhotoIds, storefrontCoverId]);

  const tagContent = useMemo(() => {
    return showBadges ? <ResponsiveVendorTagV2 size={tagSize} badges={badges} /> : undefined;
  }, [showBadges, tagSize, badges]);

  const alternateImage = useMemo(() => {
    return <div className={styles.icon}>{getVendorIconV3(taxonomyNodeKey || 'all')({})}</div>;
  }, [taxonomyNodeKey]);

  return (
    <CardPhoto
      alternateImage={alternateImage}
      imageIds={imageIds}
      tagContent={tagContent}
      {...rest}
    />
  );
};

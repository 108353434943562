import { formatDateUtc } from '~/util/dateUtils';

import styles from '../vendorCard.module.less';

export function AvailabilityTag({
  displayAvailability,
  vendorAvailableDates,
}: {
  displayAvailability: boolean;
  vendorAvailableDates: number[] | null;
}) {
  const isMultiDay = vendorAvailableDates && vendorAvailableDates?.length > 1;

  if (isMultiDay) {
    return (
      <div className={styles.availability}>
        <span className={styles.availabilityBadge}>
          Available on {vendorAvailableDates?.length} of your dates
        </span>
      </div>
    );
  }

  const formattedAvailabilityDate = vendorAvailableDates?.[0]
    ? formatDateUtc(vendorAvailableDates[0], 'M/D/YYYY')
    : '';

  return (
    <div className={styles.availability}>
      <span className={styles.availabilityBadge}>
        {displayAvailability ? (
          <>
            <>Available on </>
            <span className={styles.availabilityDate}>{formattedAvailabilityDate}</span>
          </>
        ) : (
          <>Inquire to confirm availability</>
        )}
      </span>
    </div>
  );
}

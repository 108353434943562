import {
  BANDS_DJS_TAXONOMY_KEY,
  VendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorWordSingular } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';
import { VendorDetailCardView } from '@zola/svc-marketplace-ts-types';

import cx from 'classnames';

import { MusicianTypeChildKey } from '~/types/facets';
import { formatLocation } from '~/util/formatters';
import { sentenceCase } from '~/util/textUtils';

import styles from './vendorTypeAndLocation.module.less';

export const MUSICIAN_TYPE_LABELS: Record<MusicianTypeChildKey, string> = {
  'musician-type-dj': 'DJ',
  'musician-type-ceremony-only-musician': 'Ceremony only',
  'musician-type-live-solo-performer': 'Solo performer',
  'musician-type-live-band-or-ensemble': 'Band or Ensemble',
  'musician-type-production-company': 'Production company',
};

export const getMusiciansType = (musicianType?: MusicianTypeChildKey | MusicianTypeChildKey[]) => {
  if (musicianType) {
    if (Array.isArray(musicianType)) {
      return MUSICIAN_TYPE_LABELS[musicianType[0]];
    }

    return MUSICIAN_TYPE_LABELS[musicianType];
  }

  return 'Musician';
};

export const getCardVendorType = (
  vendorTypeKey?: VendorTaxonomyKey,
  musicianType?: MusicianTypeChildKey | MusicianTypeChildKey[]
) => {
  if (vendorTypeKey === BANDS_DJS_TAXONOMY_KEY && musicianType) {
    return getMusiciansType(musicianType);
  }

  return sentenceCase(getVendorWordSingular(vendorTypeKey));
};

interface VendorTypeAndLocationProps {
  small?: boolean;
  displayLocation?: string | null;
  vendorTypeKey?: VendorTaxonomyKey | null;
  details?: VendorDetailCardView | null;
  showVendorType?: boolean;
  className?: string;
}
const VendorTypeAndLocation = ({
  small,
  displayLocation,
  vendorTypeKey,
  details,
  showVendorType = true,
  className,
}: VendorTypeAndLocationProps) => {
  // @ts-expect-error FIXME: to be removed when types are updated
  const musicianType: MusicianTypeChildKey | MusicianTypeChildKey[] = details?.musicianType;
  const vendorType =
    vendorTypeKey === BANDS_DJS_TAXONOMY_KEY && musicianType
      ? getCardVendorType(vendorTypeKey, musicianType)
      : getVendorWordSingular(vendorTypeKey || undefined);

  return (
    <div
      className={cx(
        styles.vendorType,
        {
          [styles.small]: small,
        },
        className
      )}
    >
      <div className={styles.typeLocation}>
        {showVendorType && <span data-testid="vendor-type">{capitalizeFirst(vendorType)}</span>}
        {showVendorType && displayLocation && <span className={styles.dot}>&#8226;</span>}
        {displayLocation && <span data-test-id="location">{formatLocation(displayLocation)}</span>}
      </div>
    </div>
  );
};

export default VendorTypeAndLocation;
